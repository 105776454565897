import React from 'react'
import CartItems from '../Components/CartItem/CartItems';

const Cart = () => {
  
  return (
    <div className='Cart'>
      <CartItems />
    </div> 
  )
}

export default Cart